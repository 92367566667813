import { useEffect, useLayoutEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../store/user";
import CLoader from "../components/shared/CLoader";
import roleMapping from "../data/roleMapping";
import { setAxiosBaseURL } from '../plugins/axios';

const hasAccess = (selectedRole, realtivePath) => {
  let userHasAccess = true;

  if (
    selectedRole === "spclty_hub_bia_team" &&
    (realtivePath.includes("/overview") || realtivePath.includes("/details/"))
  ) {
    userHasAccess = false;
  }

  if (
    selectedRole !== "spclty_hub_bia_team" &&
    selectedRole !== "spclty_hub_admin" &&
    selectedRole !== "spclty_hub_super_admin" &&
    selectedRole !== "spclty_hub_viewer" &&
    realtivePath.includes("/specialties")
  ) {
    userHasAccess = false;
  }

  if (
    selectedRole === "spclty_hub_viewer" &&
    realtivePath.includes("/add-specialties")
  ) {
    userHasAccess = false;
  }

  if (
    selectedRole !== "spclty_hub_bia_team" &&
    selectedRole !== "spclty_hub_admin" &&
    selectedRole !== "spclty_hub_super_admin" &&
    realtivePath.includes("/add-specialties")
  ) {
    userHasAccess = false;
  }

  if (
    selectedRole !== "spclty_hub_admin" &&
    selectedRole !== "spclty_hub_super_admin" &&
    realtivePath.includes("/admin")
  ) {
    userHasAccess = false;
  }

  if (
    selectedRole !== "spclty_hub_super_admin" &&
    realtivePath.includes("/superadmin/import")
  ) {
    userHasAccess = false;
  }

  return userHasAccess;
};

const Middleware = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const realtivePath = location.pathname;

  const { roles, loader } = useSelector(({ user }) => user);
  const { statusCode } = useSelector(({ error }) => error);


  // const getURL = async () => {
  //   const response = await fetch(
  //     'https://gateway.apim-c2c-qa.lilly.com/recommend-c2i-gateway',
  //     {
  //     method: 'GET', 
  //     headers: {
  //       'Authorization': `Bearer ${sessionStorage.getItem('Token')}`,
  //       'Content-Type': 'application/json',
  //     }});
  //     const data = await response.json();
  //     sessionStorage.setItem('gateway', data.gateway);
  //     setAxiosBaseURL(data.gateway);
  // }

  // useEffect(() => {
  //   async function fetchURL() {
  //     if(!sessionStorage.getItem('gateway')) {
  //       await getURL();
  //     } 
  //   }
  //   fetchURL();
  //   if(sessionStorage.getItem('gateway') !== undefined && sessionStorage.getItem('gateway') !== null){
  //     dispatch(fetchUsers());
  //   }   
  // }, [dispatch, location.pathname, sessionStorage.getItem('gateway')]); 

  useEffect(() => {
    if(sessionStorage.getItem(
      "Token"
    )){
      dispatch(fetchUsers()); 
    } 
  }, [dispatch, location.pathname]); 

  // useEffect(() => {
  //   const getURL = async () => {
  //     const response = await fetch(
  //       'https://gateway.apim-c2c-qa.lilly.com/recommend-c2i-gateway',
  //       {
  //       method: 'GET', 
  //       headers: {
  //         'Authorization': `Bearer ${sessionStorage.getItem('Token')}`,
  //         'Content-Type': 'application/json', // adjust as needed
  //       }});
  //     const data = await response.json();
  //     sessionStorage.setItem('gateway', data.gateway);
  //     console.log(data.gateway);
  //   }
  //   getURL();
  // }, [realtivePath]);

  // useLayoutEffect(() => {
  //   dispatch(fetchUsers());
  // }, [realtivePath]);



  useEffect(() => {
    if (statusCode) {
      navigate("/error", { state: { status: statusCode } });
    }
  }, [statusCode]);

  if (loader) {
    return <CLoader size={90} />;
  }

  // Don't run on auth routes
  if (realtivePath.includes("/auth/") || realtivePath.includes("/error")) {
    return children;
  }

  let selectedRole = sessionStorage.getItem("role");
  if (roles.length && !roles.includes(selectedRole)) {
    sessionStorage.removeItem("role");
    return <Navigate to="/error" state={{ status: 401 }} />;
  }

  if (!selectedRole) {
    return <Navigate to="/error" state={{ status: 401 }} />;
  }

  if (selectedRole && !roleMapping[selectedRole]) {
    return <Navigate to="/error" state={{ status: 401 }} />;
  }

  if (!hasAccess(selectedRole, realtivePath)) {
    return <Navigate to="/unauthorized-access" />;
  }

  const BIARoutes = [
    "specialties",
    "approvedspecialities",
    "unauthorized-access",
    "error",
  ];
  if (
    selectedRole === "spclty_hub_bia_team" &&
    !BIARoutes.some((route) => realtivePath.includes(route))
  ) {
    return <Navigate to="/specialties" />;
  }

  const overviewPaths = ["/admin", "/auth/callback", "/"];
  if (overviewPaths.includes(location.pathname)) {
    return <Navigate to="/overview" />;
  }

  return children;
};

export default Middleware;
