// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import * as RoutePath from '../pages/index';
// import Error from '../error/error';
// import TopNav from '../layout/topNavBar';
// import Middleware from '../middleware/middleware';
import RoutesDef from './routeDef';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { loginRequest } from '../auth/authconfig';
import { useEffect, useState, useLayoutEffect } from 'react';

const AuthContent = () => {
  const { instance, accounts } = useMsal();
  useEffect(() => {
    const Tokengen = async () => {
      const resp = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      sessionStorage.setItem('Token', resp.accessToken);
    };
    Tokengen();
  });
  return <RoutesDef />;
};

const AuthUser = () => {
  const { instance } = useMsal();
  instance.loginRedirect(loginRequest).catch((e) => {});
  //   const handleLogin = () => {
  //     instance.loginRedirect(loginRequest).catch(e => {
  //       console.log(e);
  //   });
  //   }
  // return (
  //   <button onClick={() => handleLogin()}>Login</button>
  // )
};

const MainContent = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <AuthContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <AuthUser />
      </UnauthenticatedTemplate>
    </>
  );
};

export default function Routing() {
  return <MainContent />;
}
